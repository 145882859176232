/* FONT */
$font-family: 'hk_groteskregular', Verdana, sans-serif;
$font-family-regular: 'hk_groteskregular', Verdana, sans-serif;
$font-family-medium: 'hk_groteskmedium', Verdana, sans-serif;
$font-family-bold: 'hk_groteskbold', Verdana, sans-serif;
$font-family-semibold: 'hk_grotesksemibold', Verdana, sans-serif;

$font-size-21: 21px;
$font-size-18: 18px;
$font-size-14: 14px;
$font-size-15: 15px;
$font-size-12: 12px;
/* END FONT */



/* COLORS */
$color-primary: #2e86aa;
// $color-primary: #64CCC9;
$color-secondary: #101820;
$color-dark: #75787B;
$color-success: #28a745;
$color-danger: #CF3E3E;
$color-warning:#050505;
$color-info: #29b6f6;
$color-default: #F7F9FD;
$color-active: #2e86aa;
// $color-active: #64CCC9;
$color-second: #E89CAE;

// $color-completed: #2EC1AC;
$color-completed: #5cb85c;
// $color-inprogress: #0c0a09;
$color-inprogress: #FF6600;
$color-assigned: #9BDEAC;
$color-notassigned: #0278AE;
$color-acknowledged: #9D65C9;
$color-selfcollect: #ABC2E8;
$color-cancelled: #7D5A5A;
$color-failed: #EA5455;

$bg-color: #F5F6F9;

$muted: rgba(#484848, 20);
$gray: rgba(#484848, 60);
$black: #000000;
$white: #ffffff;
$transparent: transparent;

$skeleton-gray: #CCCCCC;

$facebook: #3b5998;
$whatsapp: #25D366;
$linkedin: #4875B4;
$twitter: #33CCFF;
$viber: #7360f2;
$telegram: #0088cc;

$word: #0180EF;
$excel: #00784B;
$pdf: #CD4141;
/* END COLORS */