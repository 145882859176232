.custom-toast {
    &.Toastify__toast-container {
        width: auto;
        min-width: 320px;
    }

    .Toastify__toast {
        margin: 10px 0;
        border-radius: 8px;
        min-height: 56px;
        align-items: center;
        display: flex;
    }
    .Toastify__toast--default {
        background-color: white;
        
        .Toastify__toast-body {
            color: $color-secondary;

            span {
                color: $color-secondary;
            }
            
            .material-icons {
                color: $color-active;
            }
        }
    }
    .Toastify__toast--dark {
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--info {
        background-color: $color-info;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--success {
        background-color: $color-active;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--warning {
        background-color: $color-warning;
            
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast--error {
        background-color: $color-second;
        
        .Toastify__toast-body {
            color: white;

            span {
                color: white;
            }
            
            .material-icons {
                color: white;
            }
        }
    }
    .Toastify__toast-body {
        padding-left: 15px;
        padding-right: 15px;
        margin: 0px;
        font-size: 16px;
        font-family: $font-family-regular;

        > div {
            display: flex;
            align-items: center;
        }
        .material-icons {
            padding-right: 10px;
        }
    }

    .Toastify__close-button {
        align-self: center;
        padding-top: 4px;
    }
}