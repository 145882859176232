.info-cards {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat( auto-fill, [col-start] minmax(220px, 1fr) [col-end]);
    grid-template-rows: repeat(2, 93px);
    margin: 20px 25px;
    .info-card {
        background-color: white;
        border: 1px solid #EAEDF2;
        border-radius: 4px;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        cursor: pointer;
        &--total-jobs {
            width: 100%;
            padding: 15px 30px 0;
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            align-items: center;
            span:first-child{
                font-size: 18px;
            }
            span:last-child{
                font-size: 21px;
                font-weight: bold;
            }
        }
        &__inner-left {
            margin: 17px;
            flex-direction: column;
            justify-content: space-between;
            display: flex;
            align-self: stretch;
        }
        &__inner-btn {
            margin-right : 10px ;
        }
        &--isloading {
            grid-column: 3 / -1;
            grid-row: 1 /3;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &__label {
            font-size: 14px;
            line-height: 17px;
            color: $color-dark;
        }
        &__total {
            font-size:24px;
            line-height: 24px;
        }
        &--piechart {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            grid-column: 1 / span 4;
            grid-row: 1 / 3;
        }
        &--completed {
           border-bottom: 4px solid $color-completed;
        }
        &--inprogress {
           border-bottom: 4px solid $color-inprogress;
        }
        &--assigned {
           border-bottom: 4px solid $color-assigned;
        }
        &--notassigned {
           border-bottom: 4px solid $color-notassigned;
        }
        &--acknowledged {
           border-bottom: 4px solid $color-acknowledged;
        }
        &--selfcollect {
           border-bottom: 4px solid $color-selfcollect;
        }
        &--cancelled {
           border-bottom: 4px solid $color-cancelled;
        }
        &--failed {
           border-bottom: 4px solid $color-failed;
        }
    }
}

.dashboard-page {
    @media (min-width: 992px) {
    .recent-activities {
        margin-right: 10px;
    }
    .delivery-chart {
        margin-left: 10px;
    }
    }
}

.recharts-wrapper{
    overflow: visible;
    svg{
        overflow: visible;
    }
}

.cd-shadow {
    box-shadow: rgba(0, 0, 0, 0.05) 0px 1px 2px 0px;
}

.max-xs-width-control-100  {
    @include respond-below('xs') {
        display: block !important;
        width: 100% !important;
    }
}

.max-xs-width-flex-100  {
    @include respond-below('xs') {
        display: flex !important;
        width: 100% !important;
    }
}

.flip-form {
    display: inline-block; 
    direction: rtl;
}

.upload-style {
    padding: 9px 25px;
    border-radius: 50px;
    margin-left: 1em;
    font-size: 1em;
    background-color: #007CDE !important;
    border: 1px solid #007CDE;
    &:hover {
    background-color: #007edec0 !important;
    border: 1px solid #007edec0;
    }
    &:disabled,
    &[disabled]{
        border: 1px solid #007edec0;
        background-color: #007edec0 !important;
    }
    svg {
        path {
        fill: #ffffff !important;
        }
    }
    @include respond-below('xs') {
       width: 80% !important;
       margin: 0em !important;
       margin-top: 4px !important;
    }
}

.invalid {
    border: 1px solid red !important;
}

.error-message {
    color: red;
    font-size: 11px;
}

.download-order-template {
    border: 0px solid #000;
    background-color: transparent;
    color: #007CDE;
}

.back-btn {
    margin-right: 30px;
    border: 0px solid black;
    background: #007cde;
    padding: 5px 20px;
    border-radius: 7px;
    color: #fff;
}

.text-area-style {
    height: 600px;
}