.custom-status-item-component {
    .custom-text {
        text-align: right;
        text-transform: uppercase;
        font-size: 12px;
        font-family: $font-family-semibold;
        color: $color-secondary;

        span {
            text-transform: uppercase;
            font-size: 12px;
            font-family: $font-family-semibold;
            color: $color-secondary;
        }

        .custom-dot {
            position: relative;
            top: 3px;
            font-size: 5px;
            color: $color-secondary;
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .custom-count {
        text-align: right;
        font-size: 20px;
        font-family: $font-family-bold;
        color: $color-secondary;

        span {
            font-size: 20px;
            font-family: $font-family-bold;
            color: $color-secondary;
        }
    }

    .custom-count-pl {
        padding-left: 10px;
    }
    
    .MuiBadge-badge {
        top: 9px;
        left: -10px;
    }
}


$color-not-assigned: #AE0264;
$color-assigned: #9BDEAC;
$color-acknowledged: #9D65C9;
$color-in-progress: #FF6600;
$color-completed: #5cb85c;
$color-text-completed: #356e35;
$color-self-collect: #51adcf;
$color-cancelled: #7D5A5A;
$color-failed: #EA5455;
$white: #FFFFFF;

.status {
    &.not-assigned {
        color: $color-not-assigned !important;
    }
    &.assigned {
        color: $color-assigned !important;
    }
    &.acknowledged {
        color: $color-acknowledged !important;
    }
    &.in-progress {
        color: $color-in-progress !important;
    }
    &.completed {
        color: $color-text-completed !important;
    }
    &.self-collect {
        color: $color-self-collect !important;
    }
    &.cancelled {
        color: $color-cancelled !important;
    }
    &.failed {
        color: $color-failed !important;
    }
}

.status-label {
    border-radius: 6px !important;
    height: 26px !important;
    border: 0px !important;

    $opacity: 0.1;

    &.w-100 {
        width: 100%;
    }
    
    &.active {
        background-color: rgba($color-primary, $opacity) !important;

        span {
            color: $color-primary;
        }
    }
    &.inactive {
        background-color: rgba($color-second, $opacity) !important;

        span {
            color: $color-second;
        }
    }

    &.not-assigned {
        background-color: rgba($color-not-assigned, $opacity) !important;

        span {
            color: $color-not-assigned;
        }
    }
    &.assigned {
        background-color: rgba($color-assigned, $opacity) !important;

        span {
            color: $color-assigned;
        }
    }
    &.acknowledged {
        background-color: rgba($color-acknowledged, $opacity) !important;

        span {
            color: $color-acknowledged;
        }
    }
    &.in-progress {
        background-color: rgba($color-in-progress, $opacity) !important;

        span {
            color: $color-in-progress;
        }
    }
    &.completed {
        background-color: rgba($color-text-completed, $opacity) !important;

        span {
            color: $color-text-completed;
        }
    }
    &.self-collect {
        background-color: rgba($color-self-collect, $opacity) !important;

        span {
            color: $color-self-collect;
        }
    }
    &.cancelled {
        background-color: rgba($color-cancelled, $opacity) !important;

        span {
            color: $color-cancelled;
        }
    }
    &.failed {
        background-color: rgba($color-failed, $opacity) !important;

        span {
            color: $color-failed;
        }
    }
}

.status-btn {
    &.not-assigned {
        button {
            background-color: $color-not-assigned !important;
        }
    }
    &.assigned {
        button {
            background-color: $color-assigned !important;
        }
    }
    &.acknowledged {
        button {
            background-color: $color-acknowledged !important;
        }
    }
    &.in-progress {
        button {
            background-color: $color-in-progress !important;
        }
    }
    &.completed {
        button {
            background-color: $color-completed !important;
        }
    }
    &.self-collect {
        button {
            background-color: $color-self-collect !important;
        }
    }
    &.cancelled {
        button {
            background-color: $color-cancelled !important;
        }
    }
    &.failed {
        button {
            background-color: $color-failed !important;
        }
    }
}

.custom-status{
    &.status-btn {
        &.not-assigned {
            button {
                background-color: $color-not-assigned !important;
                width: 100%;
                color: #fff;
            }
        }
        &.assigned {
            button {
                background-color: $color-assigned !important;
                width: 100%;
                span {
                    color: $white;
                }

            }
        }
        &.acknowledged {
            button {
                background-color: $color-acknowledged !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.in-progress {
            button {
                background-color: $color-in-progress !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.completed {
            button {
                background-color: $color-completed !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.self-collect {
            button {
                background-color: $color-self-collect !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.cancelled {
            button {
                background-color: $color-cancelled !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
        &.failed {
            button {
                background-color: $color-failed !important;
                width: 100%;
                span {
                    color: $white;
                }
            }
        }
    }
}