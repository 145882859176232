.custom-table-component {
    table {
        thead {
            tr {
                th {
                    background-color: #EAEDF2;
                    color: $color-secondary;
                    font-family: $font-family-bold;
                    font-size: 14px;
                    border: 0px;

                    * {
                        font-family: $font-family-bold;
                    }
                    
                    .material-icons {
                        font-family: 'Material Icons' !important;
                    }

                    &:first-child {
                        border-top-left-radius: 4px;
                        border-bottom-left-radius: 4px;
                    }
                    &:last-child {
                        border-top-right-radius: 4px;
                        border-bottom-right-radius: 4px;
                    }
                }
            }
        }

        tbody {
            tr {
                border-bottom: 1px solid #EAEDF2;
                
                td {
                    color: $color-secondary;
                    font-family: $font-family-medium;
                    font-size: 14px;
                    // border: 0px;

                    * {
                        font-family: $font-family-medium;
                    }
                }
            }
            /* for transport operator input*/
            #outlined-number {
                min-width: 100px;
            }
        }
    }

    .custom-groupbar {
        margin-bottom: 10px;
        > div {
            > div {
                background-color: transparent !important;
                border-radius: 4px !important;
            }
        }
    }



    &.job-table {
        table {
            tbody {
                tr {
                    border: 0px !important;
                }
            }
        }
        // .MuiIconButton-label{
        //     display: none;
        // }
    }
    
    &.job-table-list {
        table {
            tbody {
                tr {
                    &.MuiTableRow-root {
                        border-bottom: 1px solid #EAEDF2!important;
                        // border: 0px !important;
                        height: auto !important;

                        td {
                            border: 0px !important;
                        }
                    }
                }
            }
        }

        &.v-top {
            tr {
                vertical-align: top !important;
            }
        }
    }

    
    &.import-table {
        &.has-overflow {
            height: 500px;
            overflow: auto;
        }

        table {
            tbody {
                tr {
                    td {
                        padding-left: 5px !important;
                        padding-right: 5px !important;
                        padding-top: 5px;
                        padding-bottom: 5px;
                    }
                }
            }
        }
    }
}

.custom-grid-header {
   
    .MuiGrid-container {
        background-color: #EAEDF2;
        min-height: 50px;
        border-radius: 4px;
        margin-right: 0px;
        .MuiGrid-item {
            color: $color-secondary;
            font-family: $font-family-bold;
            font-size: 14px;
            border: 0px;

            * {
                font-family: $font-family-bold;
            }
            
            .material-icons {
                font-family: 'Material Icons' !important;
            }
        }
    }
}

.custom-head-thead {
    .custom-head-tr {
        .custom-head-th {
            background-color: #EAEDF2;
            color: $color-secondary;
            font-family: $font-family-bold;
            font-size: 14px;
            border: 0px;
            padding-top: 5px;
            padding-bottom: 5px;
            padding-left: 5px;
            padding-right: 5px;
            text-align: left !important;

            &:first-child {
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                padding-left: 5px;
            }
            &:last-child {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
                padding-right: 5px;
            }
        }
    }
}
.route-optimize {
    .MuiTableRow-head {
        display: none;
    }
}

.report-table {
    thead {
        tr {
            th {
                font-weight: 500;
                line-height: 1.5rem;
                padding: 16px;
            }
        }
    }

    tbody {
        tr {
            td {
                font-weight: 500;
                line-height: 1.5rem;
                padding: 16px;
                border-bottom: 1px solid rgb(234, 237, 242);

                a, span {
                    font-size: 14px;
                }
            }
        }
    }
}

.sequence-circle {
    padding: 5px 11px;
    background: $color-active;
    color: #fff;
    border-radius: 50%;
    float: left;
    font-size: 12px;
    font-weight: bold;
}

.float-right {
    float: right;
    width: 75%;
}

.bg-complete {
    background-color: #dff0d8;
}

.MuiBox-root {
    .MuiBox-root-108{
    background-color: transparent;
    }
    &.MuiBox-root-109 {
    background-color: transparent;
    }
}

.table-head-style {
    background-color: #565553;
    height: 50px;
    text-align: left;
    .table-head-style-th {
        color:#fff;
        padding-left: 20px;
    }
}

.even-style{
    text-align: left;
    height:50px;
    &.even-style:nth-child(even) {
    background: #f0f0f0;
    }
    .table-head-style-td {
        padding-left: 20px;
    }
}

.even-style-plan {
    text-align: left;
    height:50px;
    border-top: 2px solid #a0a0a0;
    .table-head-style-td {
        padding-left: 20px;
    }
}

.even-style-total {
    text-align: left;
    height:50px;
    border-top: 2px solid #a0a0a0;
    font-weight: bold;
    .table-head-style-td {
        padding-left: 20px;
    }
}

.style-tb {
    border: 1px solid rgb(190, 190, 190);
    border-radius: 5px;
    tr{
        td {
        padding:6px;
        }
    }
    tr:nth-child(even) {
        background-color: #dddddd;
    }
}
