.header-profile {
    button {
        span {
            text-transform: capitalize;
            color: white;
            padding-left: 15px;
            padding-right: 15px;
        }
        svg {
            fill: white;

            rect, g, path {
                fill: white;
            }
        }
    }
}
.noti-overlay {
    opacity: .5 !important;
    background-color: #000;
}
#customized-menu {
    .MuiPopover-paper {
        left: 718px;
    }
    .header {
        background: #424242;
        padding: 12px;
        color: #fff;
    }
    ul {
        padding: 0px;
        max-height: 300px;
        overflow-y: auto;
        li {
            white-space: unset;
        }
    }
    .noti-footer {
        text-align: center;
        font-weight: 700;
        border-top: 1px solid #bbb;
        padding: 7px 0;
        font-size: 11px;
        cursor: pointer;
        color: #4b7daa;
    }
}
.customized-menu-item {
    min-width: 350px;

    img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: inline-block;
    }
    .details {
        margin-left: 10px;
        display: inline-block;
        .description {
            display: block;
            max-width: 248px;
            font-size: 12px;
            color: #585858;
        }
        .time {
            display: block;
            font-size: 11px;
            font-weight: 200;
            color: #aaa;
            text-align: left;
            .icon {
                vertical-align: top;
            }
            .time-text {
                font-size: 11px;
                font-weight: 200;
                color: #aaa;
            }
        }
    }
}
.notification-list {
    .time{
        text-align: left;
    }
}
#profile-menu {
    .MuiPopover-paper {
        padding: 0px 0px 0px 0px;
        overflow: hidden;
        max-height: 100%;

        ul {
            padding: 0px 0px 0px 0px;
        }
    }

    .user-info-right {
        padding-left: 20px;
    }
}
.user-info {
    padding: 6px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 200px;
    &-name {
        font-weight: bold;
    }
    &-email {
        color: #ADAFB3;
    }

}

.user-info-divider {
    max-width: 85%;
    margin: 0 auto !important;
}

.profile-pic-logo {
    border-radius: 50%;
    object-fit: cover;
}

.MuiToolbar-root {
    .MuiBadge-root {
        padding: 15px 0;

        &:hover {
            cursor: pointer;
        }
    }
}